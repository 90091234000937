import { render, staticRenderFns } from "./live-goods-list.vue?vue&type=template&id=931c4196&scoped=true"
import script from "./live-goods-list.vue?vue&type=script&lang=js"
export * from "./live-goods-list.vue?vue&type=script&lang=js"
import style0 from "./live-goods-list.vue?vue&type=style&index=0&id=931c4196&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "931c4196",
  null
  
)

export default component.exports