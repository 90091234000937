<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      v-if="!isBlank"
    >
      <van-grid :column-num="2" gutter="12" :border="false">
        <van-grid-item
          @click="goToUrl(shop.id)"
          v-for="(shop, s_index) in list"
          :key="s_index"
          v-show="shop.distance * 1 <= 15"
        >
          <template default>
            <div class="goods-layout">
              <div class="img-box">
                <img :src="shop.shop_logo" />
              </div>
              <div class="goods-detail">
                <div class="title-distance">
                  <span class="title">{{ shop.shop_name }}</span>
                  <span class="distance">{{ shop.distance }}km</span>
                </div>
                <div class="grade">
                  <div class="xingxing-box">
                    <img src="~@/assets/common/icon-good-opinion.png" />
                  </div>
                  <span>{{ shop.shop_score }}分</span>
                </div>
                <div class="location">
                  <span class="address">{{ shop.address }}</span>
                  <div
                    class="address-img-box"
                    @click="setNavigation(s_index)"
                    @click.stop
                  >
                    <img src="~@/assets/common/the_map_icon@2x.png" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </van-grid-item>
      </van-grid>
    </van-list>
    <!-- 空白页 -->
    <div class="blank-page" v-if="isBlank">
      <div class="block">
        <img src="~@/assets/blank/icon_2@2x.png" class="blank-img" />
        <span>当前定位没有店铺,请选择定位试试</span>
        <div class="button-blank" @click="selectLocation">选择定位</div>
      </div>
    </div>
    <Navigation
      ref="Navigation"
      v-if="showNavigation"
      :detailInfo="shopInfo"
      @closeNavigation="closeNavigation"
    ></Navigation>
  </div>
</template>

<script>
import { getShopList } from "@/api/shop.js";
import Navigation from "@/components/map/navigation";

export default {
  name: "live-goods-list",
  props: ["shopClassId", "shopName", "byType"],
  components: {
    Navigation,
  },
  data() {
    return {
      showNavigation: false,
      shopInfo: { id: 0, is_collect: 0 },
      loading: false,
      finished: false,
      lat: 0,
      lon: 0,
      list: [],
      page: 0,
      isNext: false,
      isBlank: false,
    };
  },
  watch: {
    shopClassId(n, o) {
      this.classId = n;
      // console.log("监听---------", "new", n, "---------old", o);
    },
  },
  methods: {
    setNavigation(key) {
      this.shopInfo = this.list[key];
      this.showNavigation = true;
      this.$refs.Navigation.showMap();
    },
    closeNavigation() {
      this.showNavigation = false;
    },
    onLoad() {
      this.page++;
      // console.log("this.page=========", this.page);
      this.getShopList();
    },
    selectLocation() {
      this.$emit("selectPosition");
    },
    setLocation(lat, lon) {
      this.lat = lat;
      this.lon = lon;
    },
    startShopList(id) {
      // 重新初始化
      let _that = this;
      this.isNext = false;
      this.list = [];
      this.page = 1;
      setTimeout(function () {
        _that.getShopList(id);
      }, 5);
    },
    async getShopList(id) {
      let classId = 0;
      if (id === 0 || id === undefined) {
        classId = 0;
      } else {
        classId = id;
      }
      if (this.isNext || !this.lon || !this.lat) {
        return false;
      }
      // console.log(" this.shopName,=========", this.shopName);
      // console.log(" classId,=========", classId);
      // console.log(" this.byType,=========", this.byType);
      // console.log(" this.lon,=========", this.lon);
      // console.log(" this.lat,=========", this.lat);
      // console.log(" this.page,=========", this.page);
      const res = await getShopList({
        shop_name: this.shopName,
        class_id: classId,
        by_type: this.byType,
        lon: this.lon,
        lat: this.lat,
        page: this.page,
      });
      if (res.code * 1 == 1) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].distance = this.getDistance(
            res.data[i].lat,
            res.data[i].lon
          );
          if(res.data[i].distance<=15){
            this.list.push(res.data[i]);
          }
        }

        if ( this.list.length <= 0) {
          this.$emit("showBlank", true);
          this.isBlank = true;
          return;
        } else {
          this.isBlank = false;
        }
        console.log(
          "-------------------------------------------------------------------"
        );
        if (res.data.length < 15) {
          this.isNext = true;
          this.finished = true;
          this.page = 1;
        }
        this.loading = false;
      }
    },
    getDistance(lat2, lng2) {
      let lat1 = this.lat;
      let lng1 = this.lon;
      let radLat1 = (lat1 * Math.PI) / 180.0;
      let radLat2 = (lat2 * Math.PI) / 180.0;
      let a = radLat1 - radLat2;
      let b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      return s;
    },
    goToUrl(shopId) {
      this.$router.push({ name: "shop-home", query: { shopId: shopId } });
    },
  },
};
</script>

<style lang="less" scoped>
// 公共样式
img {
  width: 100%;
  display: block;
}
// vant组件样式修改
/deep/.van-grid-item__content {
  padding: 0;
  border-radius: 10px;
  justify-content: start;
}
/deep/.van-grid-item {
  width: 188px;
  margin-top: 10px !important;
}
.goods-layout {
  width: 188px;
  .img-box {
    width: 188px;
    height: 188px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    img {
      width: 188px;
      height: 188px;
    }
  }
}

/deep/.van-grid {
  padding-left: 12px !important;
}
// 商品详情
.goods-detail {
  color: #aaaaaa;
  font-size: 12px;
  padding: 10px;
  .title-distance {
    display: flex;
    align-items: end;
    .title {
      width: 110px;
      font-size: 16px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .grade {
    display: flex;
    align-items: center;
    padding: 8px 0;
    .xingxing-box {
      width: 12px;
    }
    span {
      margin-left: 10px;
    }
  }
  .location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .address-img-box {
      flex-shrink: 0;
      width: 26px;
      padding-left: 10px;
      img {
        display: block;
        height: 26px;
        height: 26px;
      }
    }
    .address {
      font-size: 14px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.blank-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #fff;
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .blank-img {
      display: block;
      width: 155px;
      height: 155px;
    }
    span {
      margin: 18px 0 26px 0;
      font-size: 18px;
      color: #aaaaaa;
    }
  }
  .button-blank {
    width: 207px;
    height: 52px;
    background: linear-gradient(260deg, #ed301d 0%, #fe5876 100%);
    box-shadow: 0px 2px 4px rgba(199, 8, 8, 0.26);
    opacity: 1;
    border-radius: 28px;
    line-height: 52px;
    text-align: center;
    font-size: 21px;
    color: #fff;
  }
}
</style>
